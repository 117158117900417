:root {
    --border-size: 0.2vmin;
    --big-border-size: 1vmin;
    --grid-size: 80vmin;
    --controls-size: 10vmin;
    --font-size: 6vmin;
    --bg-color: #F5F5F5;
    --fg-color: #090909;
    --static-color: #BABBC9;
    --static-error-color: #DC965A;
    --error-color: #E57A44;
}

html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overscroll-behavior: none;
    background-color: var(--bg-color);
    color: var(--fg-color);
    font-family: monospace;
}

.grid {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-template-columns: repeat(9, 1fr);
    width: var(--grid-size);
    height: var(--grid-size);
    column-gap: 0;
    row-gap: 0;
}

.cell {
    position: relative;
    border: var(--border-size) solid var(--fg-color);
}

.cell.static {
    background-color: var(--static-color);
}

.cell.invalid {
    background-color: var(--error-color);
}

.cell.invalid.static {
    background-color: var(--static-error-color);
}

.cell:nth-child(n):nth-child(-n+9) {
    border-top-width: var(--big-border-size);
}

.cell:nth-child(3n) {
    border-right-width: var(--big-border-size);
}

.cell:nth-child(9n+1) {
    border-left-width: var(--big-border-size);
}

.cell:nth-child(n+19):nth-child(-n+27),
.cell:nth-child(n+73):nth-child(-n+81),
.cell:nth-child(n+46):nth-child(-n+54) {
    border-bottom-width: var(--big-border-size);
}

.fill {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
}

.value {
    width: 100%;
    font-size: 6vmin;
}

.value input {
    color: var(--fg-color);
    outline: none;
    padding: 0;
    border: 0;
    font-size: 6vmin;
    font-family: inherit;
    width: 100%;
    text-align: center;
    background-color: rgba(black, 0);
}

.controls {
    height: var(--controls-size);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    margin-bottom: 2vmin;
    align-items: center;
}

.controls button {
    font-size: calc(var(--font-size) * 0.6);
    font-family: inherit;
    height: 100%;
    width: calc(var(--grid-size) / 9 * 3);
    margin: 0;
    padding: 1vmin 2vmin;
    text-decoration: none;
    text-align: center;
    background-color: var(--static-color);
    color: var(--fg-color);
    border: calc(var(--big-border-size)) solid var(--fg-color);
    cursor: pointer;
    display: inline;

    &:hover,
    &:focus {
        font-weight: 600;
    }
}

.controls .time {
    display: block;
    font-size: calc(var(--font-size) * 0.8);
    text-align: right;
}
